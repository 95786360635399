import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {updateCollection} from '../services/CollectionFunctions';
import {toast} from 'react-toastify';
import Modal from 'react-modal';
import {refresh} from '../services/UserFunctions';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width:'50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root')

class UpdateCollection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cname: this.props.collection.cname,
            description: this.props.collection.description
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        refresh();
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        const collection = {
            cname: this.state.cname,
            description: this.state.description,
        }

        console.log(this.state);

        updateCollection(collection).then(res => {
            if (!res.error) {
                this.props.history.push({
                    pathname: '/collection',
                    state: {success_old: true, cname: res.cname, description: res.description}
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                style={customStyles}
                contentLabel="Collection Description">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-5 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <h6 className="h3 mb-3 font-weight-normal">Collection Description</h6>

                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input type="text"
                                       name="description"
                                       className="form-control"
                                       required="required"
                                       placeholder="Enter a short description"
                                       value={this.state.description}
                                       onChange={this.onChange}/>
                            </div>

                            <button onClick={() => this.props.applyDescription(this.state.description)} className="btn btn-primary mx-auto mr-5">Save</button>
                            <button onClick={this.props.onHide} className="btn btn-light ml-2">Close</button>
                        </form>
                    </div>
                </div>
            </div>
            </Modal>
        );
    }

}

export default withRouter(UpdateCollection);