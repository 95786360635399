import React, { Component } from 'react';
import { deleteCase } from '../services/CaseFunctions';
import { Link, withRouter } from 'react-router-dom';
import './Case.css';
import { GUEST_ROLE } from '../constants/index';
import { toast } from 'react-toastify';
import { verifyToken } from '../services/UserFunctions';

class Case extends Component {

    constructor(props){
        super(props);
        this.state = {
            cname: this.props.match.params.cname
        }
    }

    handleClick() {
        if (window.confirm('Are you sure you wish to delete this case?'))
            this.onCancel(this.props.caso)
    }

    componentDidMount() {
        verifyToken();
    }

    render() {
        return (
            <tr key={this.props.caso.cid}>

                <td><Link to={{
                    pathname: "/collection/"+ this.props.caso.cname + "/case/"+ this.props.caso.cid +"/slidelist",
                    state: { cname: this.props.caso.cname, cid: this.props.caso.cid, role: this.props.role }
                }} className="nav-link">{this.props.caso.cid} </Link></td>

                <td>{this.props.caso.case_name}</td>
                {this.props.role !== GUEST_ROLE ? (
                    <td>
                        <Link id="link1" style={{ textDecoration: 'none' }} to={{
                            pathname: "/updatecase",
                            cname: this.props.caso.cname,
                            case_name: this.props.caso.case_name,
                            cid: this.props.caso.cid
                        }}>
                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-pencil-square"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </Link>
                    </td>
                ) : null
                }
                {this.props.role !== GUEST_ROLE ? (
                    <td>
                        <svg onClick={() => this.handleClick()} width="1.5em" height="1.5em" viewBox="0 0 16 16"
                            className="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
                        </svg>
                    </td>) : null}
            </tr>
        );
    }

    onCancel(caso) {
        deleteCase(caso).then(res => {
            if (!res.error) {
                toast.success('Case ' + caso.cid + ' deleted successfully', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.caseToRemove(caso);
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }
}

export default withRouter(Case);


