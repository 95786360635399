import React, { Component } from 'react';
import { login } from '../services/UserFunctions';
import './login.css';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: ''
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    useStyles = makeStyles((theme) => ({
        paper: {
          marginTop: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        avatar: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main,
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
        },
      }));

    componentDidMount() {
        const now = new Date();
        if (now.getTime() >= localStorage.expires_in) {
            localStorage.removeItem('user_token');
            localStorage.removeItem('expires_in')
            localStorage.removeItem('refreshes_in')
            localStorage.removeItem('is_admin')
            this.props.history.push('/login');
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();

        const user = {
            email: this.state.email,
            password: this.state.password
        }

        login(user).then((res) => {
            if (!res.error) {
                this.props.history.push('/home');
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    render() {
            return(
            <div className = "container" >
                    <div className="row">
                        <div className="col-md-6 mt-5 mx-auto">
                        <h5 component="h1" id="title" variant="h5" style={{alignItems:"center", textAlign: "center"}}>
                            Sign in to your CPG-AI account
                        </h5>
                        <hr></hr>
                            <form noValidate onSubmit={this.onSubmit}>
                                <div className="form-group" style={{marginTop:"5%"}}>
                                    <label htmlFor="email">Email Address</label>
                                    <input type="email"
                                        name="email"
                                        className="form-control"
                                        required="required"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onChange={this.onChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input type="password"
                                        name="password"
                                        className="form-control"
                                        required="required"
                                        placeholder="Password"
                                        value={this.state.password}
                                        onChange={this.onChange} />
                                </div>
                                  <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    id="login_button"
                                    className={this.useStyles.submit}
                                >
                                Sign In
                                </Button>
                            </form>
                        </div>
                    </div>
            </div>
        )
    }
}

export default Login;