import React, { Component } from 'react';
import Modal from 'react-modal';
import './TagLegendModal.css';
import {getTagList} from '../services/AnnotationFunctions';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width:'50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root')

export default class TagLegendModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            tags: [{}]
        }
        this.getLegend = this.getLegend.bind(this);
    }

    getLegend(){
        getTagList().then(res => {
            this.setState({
                tags: res
            })
        })
    }

    componentDidMount(){
        this.getLegend();    
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                style={customStyles}
                contentLabel="Legenda Tag">
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            <h4 id="annotators_title">Tag List </h4>
                        </div>
                    </div>
                    <div className="jumbotron">
                    <div className="row">
                        <div className="col-sm" id="tag_table">
                        <table className="table table-responsive">
                            <tbody>
                            <tr>
                                <td>Tag Name</td>
                                <td>Color</td>
                            </tr>
                            {this.state.tags.map((tag, i) =><tr className="tags" key={i}><td>{tag.annotation}</td><td><span style={{backgroundColor: tag.color}} className="dot"></span></td></tr>)}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                    <br></br>
                    <div className="row">
                    <div className="col-sm">
                        <button className="btn btn-light" id="close_button" onClick={this.props.onHide}>Close</button>
                    </div>
                </div>
                </div>
            </Modal>
        );
    }
}