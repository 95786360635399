import React, {Component} from 'react';

export class SlideDescr extends Component {

    constructor() {
        super();
        this.state = {
            description: '',
        }

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
                <div className="row" style={{marginBottom: "3%"}}>
                    <input className="form-control" name="description" type="text" placeholder="Slide Description" id="slide_description" onChange={this.props.onChange}/>
                </div>
        );
    }

}