import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {newCollection} from '../services/CollectionFunctions';
import {refresh} from '../services/UserFunctions';

class NewCollection extends Component {

    constructor(props) {
        super();
        this.state = {
            cname: '',
            description: '',
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        refresh();
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        const collection = {
            cname: this.state.cname,
            description: this.state.description,
        }

        newCollection(collection).then(res => {
            if (!res.error) {
                this.props.history.push({pathname: '/collection', state: {success: true, cname: res.cname}});
            }
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-5 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <h1 className="h3 mb-3 font-weight-normal">New Collection</h1>
                            <div className="form-group">
                                <label htmlFor="cname">Collection Name</label>
                                <input type="text"
                                       name="cname"
                                       className="form-control"
                                       required="required"
                                       placeholder="Collection Name"
                                       value={this.state.cname}
                                       onChange={this.onChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input type="text"
                                       name="description"
                                       className="form-control"
                                       required="required"
                                       placeholder="Short Description"
                                       value={this.state.description}
                                       onChange={this.onChange}/>
                            </div>
                            <button type="submit" className="btn btn-primary mx-auto mr-5">Save</button>
                            <Link to="/collection">
                                <button className="btn btn-light ml-2">Back</button>
                            </Link>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(NewCollection);