import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class Navbar extends Component {

    logOut() {
        localStorage.removeItem('user_token');
        localStorage.removeItem('expires_in')
        localStorage.removeItem('refreshes_in')
        localStorage.removeItem('is_admin')
        this.props.history.push('/login');
    }

    render() {
        const loginRegLink = (
                <li className="navbar-item">
                    <Link className="nav-link" to="/login">Login</Link>
                </li>
        );

        const userLink = (
            <React.Fragment>                
                <li className="navbar-item">
                    <Link className="nav-link" to="/profile">User</Link>
                </li>
                <li className="navbar-item">
                    <a className="nav-link" onClick={this.logOut.bind(this)} href="/login">Logout</a>
                </li>
            </React.Fragment>
        )

        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-left">
                <button className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbar1"
                    aria-controls="navbar1"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-md-left mx-auto" id="cpglink">
                    <a className="navbar-brand" href="http://cloudpathology.eu/" target="_blank" rel="noreferrer">
                        <img src="logo500.png" width="40" height="40" className="d-inline-block align-top" alt="CPG Website"/>
                        Cloud Pathology - AI Division
                    </a>
                </div>

                <div className="collapse navbar-collapse justify-content-md-right mx-auto" id="navbar1">
                    <ul className="navbar-nav justify-content-md-right mx-auto">
                        <li className="nav-item">
                            {localStorage.user_token ? <Link to="/home" className="nav-link">Dashboard</Link> : ''}
                        </li>
                        <li className="nav-item">
                            {localStorage.user_token ?
                                <Link to="/collection" className="nav-link">Collection</Link> : ''}
                        </li>
                        <li className="nav-item">
                            {!localStorage.user_token ?  
                            <Link to="/register" className="nav-link">Register</Link> : '' }
                        </li>
                    {localStorage.user_token ? userLink : loginRegLink}
                    </ul>
                </div>
            </nav>
        )
    }
}

export default withRouter(Navbar);