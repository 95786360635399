import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './Case.css';
import { ADMIN_ROLE, API_URL, GUEST_ROLE } from '../constants/index';
import { deleteSlide, getThumbnail, setCompleted, getOriginal } from '../services/SlideFunction';
import { toast } from 'react-toastify';
import { verifyToken, logOut } from '../services/UserFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrain } from '@fortawesome/free-solid-svg-icons'

class Slide extends Component {

    constructor(props) {
        super(props);
        this.state = {
            thumbnail: '',
            width: '',
            height: '',
            slide: {},
            downloadUrl: API_URL + 'collection/'+this.props.slide.cname+'/case/'+this.props.slide.cid+'/slide/'+this.props.slide.sid+'/original/'
        }
    }

    componentDidMount() {
        verifyToken();
        getThumbnail(this.props.slide).then(res => {
            if(res.status === 401){
                logOut(this);
            }

            this.setState({
                thumbnail: res.thumbnail,
                width: res.width/1.5 + 'px',
                height: res.height/1.5 + 'px',
                slide: this.props.slide,
            })
        })
    }

    handleClick() {
        if (window.confirm('Are you sure you wish to delete this slide?'))
            this.onCancel(this.props.slide)
    }

    onCancel(slide) {
        deleteSlide(slide).then(res => {
            if(res.status === 401){
                logOut(this);
            }
            if (!res.error) {
                this.props.slideToRemove(slide);
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    setCompleted() {
        setCompleted(this.state.slide).then(res => {
            if(res.status === 401){
                logOut(this);
            }
            this.setState({
                slide: res
            })
        })
    }

    downloadTile(){
        getOriginal(this.props.slide).then(res => {
            if(res.status === 401){
                logOut(this);
            }
            this.createAndDownloadBlobFile(res, this.props.slide.sid, 'ndpi');        
        })
    }

    createAndDownloadBlobFile(blob, filename, extension = 'ndpi') {
        const fileName = `${filename}.${extension}`;
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          // Browsers that support HTML5 download attribute
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

    render() {
        return (
            <React.Fragment>
            <tr key={this.props.key}>
                <td>
                    {this.props.slide.sid}
                </td>
                <td><Link id="viewer_link" style={{ textDecoration: 'none' }} to={{
                    pathname: "/collection/"+ this.props.slide.cname +"/case/"+ this.props.slide.cid+"/slide/"+ this.props.slide.sid,
                    role: this.props.role,
                    cname: this.props.slide.cname,
                    cid: this.props.slide.cid,
                    sid: this.props.slide.sid,
                    page: this.props.page,
                    tilesource: API_URL + 'collection/' + this.props.slide.cname + '/case/' + this.props.slide.cid + '/slide/' + this.props.slide.sid + '.dzi/'
                }}>
                    <img className="thumbnail" width={this.state.width} height={this.state.height}
                        src={this.state.thumbnail} alt="Thumbnail"/>
                </Link>
                </td>
                <td>{this.state.slide.description}</td>
                {this.state.slide.completed ?
                    <td>
                        <svg id="check_fill" width="1.5em" style={{ color: "#00A13C" }} onClick={() => this.setCompleted()}
                            height="1.5em" viewBox="0 0 16 16" className="bi bi-check-circle" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path fillRule="evenodd"
                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                        </svg>
                    </td> :
                    <td>
                        <svg id="no_check_fill" width="1.5em" style={{ color: "red" }} onClick={() => this.setCompleted()}
                            height="1.5em" viewBox="0 0 16 16" className="bi bi-check-circle" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path fillRule="evenodd"
                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                        </svg>
                    </td>}
                    { this.props.role == ADMIN_ROLE ?
                    this.props.slide.predicted ? 
                    <td>
                        <FontAwesomeIcon icon={faBrain} color="#3E92CC" title="Slide predicted" size="lg"/>
                    </td>
                    : <td>
                        <FontAwesomeIcon icon={faBrain} title="Slide not already predicted" size="lg"/>  
                      </td>
                    : null}   
                {this.props.role !== GUEST_ROLE ? (
                    <td className="button_click">
                        <svg onClick={() => this.downloadTile()} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                        </svg>
                    </td>
                ) : null}
                {this.props.role !== GUEST_ROLE ? (
                    <td>
                        <Link id="link1" style={{ textDecoration: 'none' }} to={{
                            pathname: "/updateslide",
                            cname: this.props.slide.cname,
                            description: this.state.slide.description,
                            cid: this.props.slide.cid,
                            sid: this.props.slide.sid
                        }}>
                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-pencil-square"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                        </Link>
                    </td>
                ) : null}
                {this.props.role !== GUEST_ROLE ? (
                    <td className="button_click">
                        <svg onClick={() => this.handleClick()} width="1.5em" height="1.5em" viewBox="0 0 16 16"
                            className="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
                        </svg>
                    </td>) : null}
            </tr>
            </React.Fragment>
        );
    }

}

export default withRouter(Slide);