import React, {Component} from 'react';
import {setNewPassword} from '../services/UserFunctions';
import {toast} from 'react-toastify';
import {refresh} from '../services/UserFunctions';
import {Link} from 'react-router-dom';

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

export default class ChangePassword extends Component {

    logOut() {
        localStorage.removeItem('user_token');
        localStorage.removeItem('expires_in')
        localStorage.removeItem('refreshes_in')
        localStorage.removeItem('is_admin')
        this.props.history.push('/login');
    }

    constructor() {
        super();
        this.state = {
            password: '',
            confirm_password: '',
            errors: {
                password: '',
                confirm_password: ''
            }
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        refresh();
    }

    onChange(e) {
        this.handleChange(e);
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        const newUser = {
            password: this.state.password,
            confirm_password: this.state.confirm_password,
        }

        if (validateForm(this.state.errors)) {
            setNewPassword(newUser).then(() => {
                this.logOut();
            });
        } else {
            toast.error('Invalid Form', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const {name, value} = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'password':
                errors.password =
                    value.length < 8
                        ? 'Password must be 8 characters long!'
                        : '';
                break;
            case 'confirm_password':
                errors.confirm_password = value !== this.state.password
                    ? 'The two passwords must match'
                    : '';
                break;
            default:
                break;
        }
        this.setState({errors, [name]: value});
    }

    render() {
        const {errors} = this.state;
        return (
            <div className="container" hidden={this.state.show_pwd}>
                <div className="row">
                    <div className="col-md-6 mt-5 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password"
                                       name="password"
                                       className="form-control"
                                       required="required"
                                       placeholder="Password"
                                       value={this.state.password}
                                       onChange={this.onChange}
                                       noValidate/>
                                {errors.password.length > 0 &&
                                <span style={{color: "red"}} className='error'>{errors.password}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirm_password">Confirm Password</label>
                                <input type="password"
                                       name="confirm_password"
                                       className="form-control"
                                       required="required"
                                       placeholder="Confirm password"
                                       value={this.state.confirm_password}
                                       onChange={this.onChange}
                                       noValidate/>
                                {errors.confirm_password.length > 0 &&
                                <span style={{color: "red"}} className='error'>{errors.confirm_password}</span>}
                            </div>
                            <button type="submit" className="btn btn-lg btn-primary mx-auto mr-5">Change</button>
                            <Link to={{pathname: "/profile"}}>
                                <button className="btn btn-lg btn-light ml-2">Back</button>
                            </Link>
                        </form>
                    </div>
                </div>
            </div>)
    }

}