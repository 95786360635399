import axios from 'axios';
import { API_URL } from '../constants/index';
import logError from '../utils/log';

axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.user_token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getCollection = () => {
    return axios
        .get(API_URL + 'collection/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch( error => {
            if(error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const newCollection = (collection) => {
    return axios
        .post(API_URL + 'collection/', {
            cname: collection.cname,
            description: collection.description
        })
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch( error => {
            if(error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })

}

export const deleteCollection = (cname) => {
    return axios
        .delete(API_URL + 'collection/' + cname + '/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch( error => {
            if(error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const updateCollection = (collection) => {
    return axios
        .put(API_URL + 'collection/' + collection.cname + '/', {
            cname: collection.cname,
            description: collection.description
        })
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch( error => {
            if(error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}