import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { API_URL } from '../constants/index';
import logError from '../utils/log';
import { toast } from 'react-toastify';

axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.user_token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const verifyToken = () => {
    const now = new Date().getTime();
    if (now >= localStorage.refreshes_in) {
        console.log('refresh');
        refresh();
    }
}

export const logOut = (e) => {
    localStorage.removeItem('user_token');
    localStorage.removeItem('expires_in')
    localStorage.removeItem('refreshes_in')
    localStorage.removeItem('is_admin')
    e.props.history.push('/login');
}

export const refresh = refreshToken => {
        return axios
            .post(API_URL + 'user/refresh/')
            .then(res => {
                localStorage.setItem('user_token', res.data.access_token)
                const expire_date = new Date().getTime() + (1000 * res.data.expires_in);
                const refresh_date = new Date().getTime() + (1000 * res.data.refreshes_in);
                localStorage.setItem('expires_in', expire_date)
                localStorage.setItem('refreshes_in', refresh_date)
                const decoded = jwt_decode(localStorage.user_token);
                localStorage.setItem('is_admin', decoded.identity.is_admin)
                return res.data
            })
            .catch(error => {
                var e = new Error();
                logError(JSON.stringify(error.response.data), e.stack, "error");
            });
}

export const register = newUser => {
    return axios
        .post(API_URL + 'user/', {
            first_name: newUser.first_name,
            last_name: newUser.last_name,
            user_email: newUser.user_email,
            password: newUser.password,
            confirm_password: newUser.confirm_password,
            is_admin: newUser.is_admin
        })
        .then(res => {
            if (!res.error) {
                return res;
            }
        }).catch(error => {
            if (error.response) {
                toast.error(error.response, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                var e = new Error();
                logError(JSON.stringify(error.response.data), e.stack, "error");
                return error.response.data;
            }
        })
}

export const login = user => {
    return axios
        .post(API_URL + 'user/login/', {
            user_email: user.email,
            password: user.password
        })
        .then(res => {
            localStorage.setItem('user_token', res.data.access_token)
            const expire_date = new Date().getTime() + (1000 * res.data.expires_in);
            const refresh_date = new Date().getTime() + (1000 * res.data.refreshes_in);
            localStorage.setItem('expires_in', expire_date)
            localStorage.setItem('refreshes_in', refresh_date)
            const decoded = jwt_decode(localStorage.user_token);
            localStorage.setItem('is_admin', decoded.identity.is_admin)
            return res.data
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const getGrantedUsers = (cname) => {
    return axios
        .get(API_URL + 'collection/' + cname + '/users/')
        .then(res => {
            if (!res.error) {
                return res.data
            }
        }).catch(error => {
            if (error.response) {
                toast.error(error.response, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                var e = new Error();
                logError(JSON.stringify(error.response.data), e.stack, "error");
                return error.response.data;
            }
        })
}

export const grantUser = (uid, user_role, cname) => {
    return axios
        .post(API_URL + 'collection/' + cname + '/grant/' + uid + '/' + user_role + '/')
        .then(res => {
            return res.data
        }).catch(error => {
            if (error.response) {
                toast.error(error.response, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const revokeUser = (cname, uid) => {
    return axios
        .delete(API_URL + 'collection/' + cname + '/revoke/' + uid + '/')
        .then(res => {
            if (!res.error) {
                return res.data
            }
        }).catch(error => {
            if (error.response) {
                toast.error(error.response, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const getNoAtuhUsers = (cname) => {
    return axios
        .get(API_URL + 'collection/' + cname + '/users/missing/')
        .then(res => {
            console.log(res);
            if (!res.error) {
                return res.data
            }
        }).catch(error => {
            if (error.response) {
                toast.error(error.response, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const setNewPassword = (newUser) => {
    return axios
        .post(API_URL + 'user/reset/',
            { password: newUser.password, confirm_password: newUser.confirm_password})
        .then(res => {
            if (!res.error) {
                return res.data
            }
        }).catch(error => {
            if (error.response) {
                toast.error(error.response, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}