import React, { Component } from 'react';
import Modal from 'react-modal';
import './UserModal.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width:'50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root')

export default class UsersModal extends Component {

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                style={customStyles}
                contentLabel="Ricomincia">
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            <h4 id="annotators_title">Annotator Users </h4>
                        </div>
                    </div>
                    <div className="jumbotron">
                    <div className="row">
                        <div className="col-sm">
                        <table className="table table-responsive">
                        { this.props.users !== null && this.props.users !== undefined ? 
                            <tbody>
                            <tr>
                                <td>User ID</td>
                                <td>First Name</td>
                                <td>Last Name</td>
                            </tr>
                            {this.props.users.map(user =><tr onClick={() => this.props.changeAnnotation(user)} className="annotators" key={user.uid}><td>{user.uid}</td><td>{user.first_name}</td><td>{user.last_name}</td></tr>)}
                            </tbody>
                            : ''}
                        </table>
                        </div>
                    </div>
                    </div>
                    <br></br>
                    <div className="row">
                    <div className="col-sm">
                        <button className="btn btn-light" id="close_button" onClick={this.props.onHide}>Close</button>
                    </div>
                </div>
                </div>
            </Modal>
        );
    }
}
