import axios from 'axios';
import { API_URL } from '../constants/index';
import logError from '../utils/log';

axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.user_token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getCases = (cname) => {
    return axios
        .get(API_URL + 'collection/' + cname + '/case/')
        .then(res => {
            if (!res.error) {
                return res.data
            }
        }).catch( error => {
            if(error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const newCase = (caso) => {
    return axios
        .post(API_URL + 'collection/' + caso.cname + '/case/', {
            description: caso.description,
            case_name: caso.name,
            p_age: caso.patient_age,
            p_sex: caso.patient_sex
        })
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch( error => {
            if(error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const deleteCase = (caso) => {
    return axios
        .delete(API_URL + 'collection/' + caso.cname + '/case/' + caso.cid + '/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch( error => {
            if(error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const updateCase = (caso) => {
    return axios
        .put(API_URL + 'collection/' + caso.cname + '/case/' + caso.cid + '/', {
            description: caso.description,
            case_name: caso.name,
            p_age: caso.patient_age,
            p_sex: caso.patient_sex
        })
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch( error => {
            if(error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}