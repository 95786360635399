import React, { Component } from 'react';
import { getReadySlide, getTodoSlide } from '../services/SlideFunction';
import { ADMIN_ROLE, API_URL, GUEST_ROLE } from '../constants/index';
import { Link, withRouter } from 'react-router-dom';
import Slide from './Slide';
import { toast } from 'react-toastify';
import { verifyToken, logOut } from '../services/UserFunctions';
import './Case.css';
import Pagination from '@material-ui/lab/Pagination';

class SlideList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cname: '',
            cid: '',
            slide_ready: [],
            page: this.props.location.state.page ? this.props.location.state.page : 1,
            totalPages: null,
            slide_todo: [],
        }
    }

    removeSlide = () => {
        getReadySlide(this.props.location.state.cname, this.props.location.state.cid, this.state.page).then(res => {
            if(res.status === 401){
                logOut(this);
            }
            if (!res.error) {
                this.setState({
                    slide_ready: res,
                    cname: this.props.location.cname,
                    cid: this.props.location.state.cid
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    slide_ready: [],
                    cname: this.props.location.cname,
                    cid: this.props.location.state.cid
                });
            }
        });
    }

    componentDidMount() {
        verifyToken();

        if (this.props.location.state) {
            if (this.props.location.state.success) {
                toast.success('Slide ' + this.props.location.state.sid + ' uploaded successfully, queued slides will be processed soon', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            if (this.props.location.state.success_old) {
                toast.success('Slide ' + this.props.location.state.sid + ' updated successfully with description: ' + this.props.location.state.description, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }

        if (this.state.slide_ready.length === 0) {
            getReadySlide(this.props.location.state.cname, this.props.location.state.cid, this.state.page).then(res => {
                if(res.status === 401){
                    logOut(this);
                }
                if (!res.error) {
                    this.setState({
                        slide_ready: res,
                        cname: this.props.location.cname,
                        cid: this.props.location.state.cid,
                        totalPages: res[0].total_pages
                    })
                } else {
                    toast.error(res.error, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        }

        if (this.state.slide_todo.length === 0) {
            getTodoSlide(this.props.location.state.cname, this.props.location.state.cid).then(res => {
                if(res.status === 401){
                    logOut(this);
                }
                if (!res.error) {
                    var slide_queued = [];
                    res.forEach(element => {
                        Object.entries(element).forEach(([key, value]) => {
                            if (key === "cname" && value === this.props.location.state.cname) {
                                if (element.cid === this.props.location.state.cid)
                                    slide_queued.push(element);
                            }
                        });
                    });
                    if (slide_queued.length > 0) {
                        this.setState({
                            slide_todo: slide_queued
                        })
                    }
                } else {
                    toast.error(res.error, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        }
    }

    changePage = (event, value) => {
        getReadySlide(this.props.location.state.cname, this.props.location.state.cid, value).then(res => {
            if(res.status === 401){
                logOut(this);
            }
            this.setState({
                page: value,
                slide_ready: res
            })
        })
    }

    render() {
        return (
            <div className="container" id="slide_list">
                <div className="jumbotron mt-5">
                    <div className="row">
                        <div className="col-sm mt-3">
                            <h1>Slides</h1>
                        </div>
                        <div className="col-sm-1 mt-1">
                            <Link to={{
                                pathname: "/collection/" + this.props.location.state.cname + "/caselist",
                                state: { cname: this.props.location.state.cname, role: this.props.location.state.role }
                            }}>
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-return-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                                </svg>
                            </Link>
                        </div>
                        {this.props.location.state.role !== GUEST_ROLE ? (
                            <div className="col-sm-1 mt-1">
                                <Link style={{ textDecoration: 'none' }} to={{
                                    pathname: "/newslide",
                                    cname: this.props.location.state.cname,
                                    cid: this.props.location.state.cid
                                }}>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16"
                                        className="bi bi-file-earmark-plus" fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                                        <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                                        <path fillRule="evenodd"
                                            d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z" />
                                    </svg>
                                </Link>
                            </div>) : null
                        }
                    </div>
                    <table className="table table-responsive" id="slide_list">
                        <thead>
                            <tr>
                                <td>ID CPG</td>
                                <td>Anteprima</td>
                                <td>ID Ospedale</td>
                                <td>Completato</td>
                                { this.props.role == ADMIN_ROLE ? <td>Predetta</td> : <td></td> }
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>{this.state.slide_ready.map(slide =><Slide page={this.state.page} key={slide.sid} slide={slide} role={this.props.location.state.role} slideToRemove={this.removeSlide}/>)}
                            {this.state.totalPages > 1 ? (
                                <tr><div style={{ width: "300px" }}><Pagination count={this.state.totalPages} color="primary" page={this.state.page} size="small" onChange={this.changePage} /></div></tr>
                            )
                                : null}
                        </tbody>
                    </table>
                </div>

                {this.state.slide_todo.length > 0 ? (
                    <div className="jumbotron mt-5">
                        <div className="row">
                            <div className="col-sm mt-3">
                                <h1>Queued Slides</h1>
                            </div>
                        </div>
                        <table className="table table-responsive col mx-auto">
                            <tbody>
                                <tr>
                                    <td>Slide ID</td>
                                    <td>Slide Path</td>
                                    <td>Slide Description</td>
                                </tr>
                                {this.state.slide_todo.map(slide_q => <tr key={slide_q.sid}>
                                    <td>{slide_q.sid}</td>
                                    <td>{slide_q.slide_path}</td>
                                    <td>{slide_q.description}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>) : null}
            </div>
        );
    }
}

export default withRouter(SlideList);