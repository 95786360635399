import axios from 'axios';
import { API_URL } from '../constants/index';
import logError from '../utils/log';

axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.user_token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const caricaAnnotations = (cname, cid, sid) => {
    return axios
        .get(API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/')
        .then(res => {
            return res;
        }).catch(error => {
            if (error.response) {
                var e = new Error();
                logError(JSON.stringify(error.response.data), e.stack, "error");
                return error.response.data;
            }
        })
}

export const createAnnotation = (cname, cid, sid, annotation) => {
    return axios
        .post(API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/', annotation)
        .then(res => {
            if (!res.error) {
                return res;
            }
        }).catch(error => {
            console.log(error);
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const updateAnnotation = (cname, cid, sid, annotation) => {
    return axios
        .put(API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/' + annotation.real_id + '/', annotation)
        .then(res => {
            if (!res.error) {
                return res;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const deleteAnnotation = (cname, cid, sid, annotation) => {
    return axios
        .delete(API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/' + annotation.real_id + '/', annotation)
        .then(res => {
            if (!res.error) {
                return res;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const getAnnotators = (cname, cid, sid) => {
    return axios
        .get(API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/editors/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const caricaUidAnnotation = (cname, cid, sid, uid, tags) => {
    var req;
    if (uid === null && tags === null) {
        req = API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/';
    } else if (uid !== null && tags === null) {
        req = API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/?editor=' + uid;
    } else if (uid === null && tags !== null) {
        req = API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/?tags=' + tags;
    } else {
        req = API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/?editor=' + uid + "&tags=" + tags;
    }

    return axios
        .get(req)
        .then(res => {
            return res;
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const getTagList = () => {
    return axios
        .get(API_URL + 'legend/')
        .then(res => {
            return res.data;
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}


export const getAvailableTags = (cname, cid, sid, uid) => {
    var req;
    if (uid === null) {
        req = API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/list/';
    } else {
        req = API_URL + 'collection/' + cname + '/case/' + cid + '/slide/' + sid + '/annotation/list/?editor=' + uid;
    }
    return axios
        .get(req)
        .then(res => {
            return res.data;
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

