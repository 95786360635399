import React, {Component} from 'react';
import jwt_decode from 'jwt-decode';
import {Link} from 'react-router-dom';
import {refresh} from '../services/UserFunctions';
import '../App.css';

class Profile extends Component {
    constructor() {
        super();
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            is_admin: false,
        }
    }

    componentDidMount() {
        refresh();
        const token = localStorage.user_token;
        const decoded = jwt_decode(token);
        this.setState({
            first_name: decoded.identity.first_name,
            last_name: decoded.identity.last_name,
            email: decoded.identity.user_email,
            is_admin: decoded.identity.is_admin
        });
    }

    showPwdForm() {
        this.setState({
            show_pwd: false
        })
    }

    render() {
        return (
            <div className="container">
                <div className="jumbotron mt-5">
                    <div className="col-sm-8 mt-5">
                        <h1 className="text-center">PROFILE</h1>
                    </div>
                    <table className="table table-responsive col-md-6 mx-auto">
                        <tbody>
                        <tr>
                            <td>First Name</td>
                            <td>{this.state.first_name}</td>
                        </tr>
                        <tr>
                            <td>Last Name</td>
                            <td>{this.state.last_name}</td>
                        </tr>
                        <tr>
                            <td>Email Address</td>
                            <td>{this.state.email}</td>
                        </tr>
                        <tr>
                            <td>Admin</td>
                            <td>{this.state.is_admin ? 'Yes' : 'No'}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="row" id="change_pwd">
                        <Link to={{pathname: "/changepassword"}}>Change Password</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;