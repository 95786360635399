export const API_URL = process.env.REACT_APP_API_ENDPOINT;
export const ADMIN_ROLE = "ADMIN";
export const EDITOR_ROLE = "EDITOR";
export const GUEST_ROLE = "GUEST";
export const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);
export const annotations = ['EPITELIO_NORMALE', 'EPITELIO_IPERPLASTICO', 'ADENOCARCINOMA', 'TONACA_MUSCOLARE', 'NECROSI', 'AGGREGATI_LINFOCITARI', 'COMPONENTE_MUCINOSA', 'TESSUTO_ADIPOSO', 'GANGLI', 'TESSUTO_GRANULAZIONE', 'MUCOSA', 'REAZIONE_DESMOPLASTICA', 'DISPLASIA_BASSO_GRADO', 'DISPLASIA_ALTO_GRADO', 'MARGINE_RESEZIONE_NEGATIVO', 'MARGINE_RESEZIONE_DISPLASIA_BASSO', 'MARGINE_RESEZIONE_DISPLASIA_ALTO', 'PLASMACELLULE', 'DISPLASIA']
export const OPTIONS = {
    url: API_URL + '/logger',
    token: '',
    timeout: 0,
    suspend: 100,
    queueSize: 0,
    trace: ['trace', 'warn', 'error'],
    depth: 0,
    json: false,
    timestamp: function () {
        return new Date().toISOString();
    },
    backoff: function (suspend) {
        var expFactor = 2;
        var jitter = 0.1;
        var maxSuspend = 30000;

        var newSuspend = suspend * expFactor;
        if (newSuspend > maxSuspend) {
            newSuspend = maxSuspend;
        }
        newSuspend += newSuspend * jitter * Math.random();
        return newSuspend;
    }
}
