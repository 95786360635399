import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {API_URL} from '../constants/index';
import logError from '../utils/log';


axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.user_token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getComments = (props) => {
    return trackPromise(axios
        .get(API_URL + 'collection/' + props.cname + '/case/' + props.cid + '/slide/' + props.sid + '/comment/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
    );
}


export const saveComment = (props, text) => {
    return trackPromise(axios
        .post(API_URL + 'collection/' + props.cname + '/case/' + props.cid + '/slide/' + props.sid + '/comment/', {
            comment: text
        })
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
    );
}

export const deleteComment = (props, commid) => {
    return trackPromise(axios
        .delete(API_URL + 'collection/' + props.cname + '/case/' + props.cid + '/slide/' + props.sid + '/comment/' + commid +'/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
    );
}