import React, { Component } from 'react';
import { deleteCollection, updateCollection } from '../services/CollectionFunctions';
import { Link, withRouter } from 'react-router-dom';
import './Collection.css';
import { toast } from 'react-toastify';
import {ADMIN_ROLE} from '../constants/index';
import UpdateCollection from './UpdateCollection';

class Collection extends Component {

    constructor(props){
        super(props);
        this.state = {
            showDescriptionModal: false
        }
        this.setDescriptionModal = this.setDescriptionModal.bind(this);
        this.applyDescription = this.applyDescription.bind(this);
    }

    setDescriptionModal(boolean) {
        this.setState({
            showDescriptionModal: boolean
        })
    }

    applyDescription(description){
        var collection = this.props.collection;
        collection.description = description;
        updateCollection(collection).then(res => {
            if (!res.error) {
                this.setDescriptionModal(false);
            } else {
                    toast.error(res.error, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    }

    handleClick() {
        if (window.confirm('Are you sure you wish to delete this item?'))
            this.onCancel(this.props.collection.cname)
    }

    render() {
        return (
            <React.Fragment>
            <UpdateCollection 
                show={this.state.showDescriptionModal}
                onHide={() => this.setDescriptionModal(false)}
                applyDescription={this.applyDescription}
                collection={this.props.collection}
            />
            <tr key={this.props.collection.cname}>
                <td> <Link to={{ pathname: "collection/" + this.props.collection.cname + "/caselist", state:{cname: this.props.collection.cname, role: this.props.collection.user_role} }} className="nav-link">{this.props.collection.cname}</Link></td>
                <td id="desc" onClick={() => this.setDescriptionModal(true)}>{this.props.collection.description}</td>
                <td>{this.props.collection.user_role}</td>
                {
                   ADMIN_ROLE === this.props.collection.user_role ? (
                        <td>
                            <Link id="link2" style={{ textDecoration: 'none' }} to={{ pathname: "/userCollection", cname: this.props.collection.cname }}>
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10zM13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                                </svg>
                            </Link>
                        </td>) : null
                }
                { ADMIN_ROLE === this.props.collection.user_role ? (
                    <td>
                        <svg onClick={() => this.handleClick()} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
                        </svg>
                    </td>) : null}
            </tr>
            </React.Fragment>
            );
    }

    onCancel(collection) {
        deleteCollection(collection).then(res => {
            if (!res.error) {
                toast.success('Collection ' + collection + ' deleted successfully', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.collectionToRemove(collection);
            }
        });
    }
}

export default withRouter(Collection);


