import React, { Component } from "react";
import { getComments, saveComment, deleteComment } from '../services/CommentFunction';
import { Comment, Form } from "semantic-ui-react";
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';

export default class CommentSection extends Component {

    constructor(props) {
        super();
        this.state = {
            comments: [],
            user_name: ""
        }
        this.saveComment = this.saveComment.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
    }

    componentDidMount() {
        if (this.props.commentSectionOpen) {
            document.getElementById("commentSection").style.display = "";
            document.getElementById("openseadragon").style.width = "75%";
        }
        const token = localStorage.user_token;
        const decoded = jwt_decode(token);
        var first_last_name = decoded.identity.first_name + " " + decoded.identity.last_name;
        getComments(this.props).then(res => {
            if (!res.error) {
                this.setState({
                    comments: res
                })
            }
        })
        this.setState({
            user_name: first_last_name
        })
    }

    saveComment() {
        saveComment(this.props, document.getElementById("comment_text").value).then(res => {
            if (!res.error) {
                getComments(this.props).then(res => {
                    if (!res.error) {
                        this.setState({
                            comments: res
                        })
                    }
                })
                toast.success('Comment created', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })

        document.getElementById("comment_text").value = "";
    }

    deleteComment(commid) {
        console.log(commid);
        deleteComment(this.props, commid).then(res => {
            if (!res.error) {
                getComments(this.props).then(res => {
                    if (!res.error) {
                        this.setState({
                            comments: res
                        })
                    }
                })
                toast.success('Comment deleted', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.props.commentSectionOpen ?
                    <div className="col-sm-3">
                        <div id="comment_container" className="jumbotron mt-2">
                            {this.state.comments.length === 0 ? <div><h4>Diagnosi</h4> <hr></hr></div> : null}
                            <Comment.Group>
                                <div id="comment_read">
                                    {this.state.comments.map((comment, i) =>
                                        <React.Fragment key={i}>
                                            <Comment>
                                                <Comment.Content>
                                                    <Comment.Author as="h6">{comment.first_last_name}</Comment.Author>
                                                    <Comment.Metadata>
                                                        <div>{comment.created_on}</div>
                                                    </Comment.Metadata>
                                                    <Comment.Text>
                                                        {comment.comment} <br></br>
                                                        {this.state.user_name === comment.first_last_name && comment.deleted_by === null ? <FontAwesomeIcon icon={faTrashAlt} className="trash_icon" title="Delete Comment" size="sm" onClick={() => this.deleteComment(comment.commid)} /> : null}
                                                        <hr></hr>
                                                    </Comment.Text>
                                                </Comment.Content>
                                            </Comment>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div id="comment_form">
                                    <Form.TextArea id="comment_text" placeholder="Diagnosi SNOMED" />
                                    <button labelPosition="left" placeholder="Diagnosi SNOMED" aria-pressed="true" className="btn btn-primary" style={{ backgroundColor: "#cc0099", color: "white" }} onClick={() => this.saveComment()} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg> Referta</button>
                                </div>
                            </Comment.Group>
                        </div>
                    </div>
                    : null}
            </React.Fragment>
        )
    }

}