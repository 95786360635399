import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { newCase } from '../services/CaseFunctions';
import { refresh } from '../services/UserFunctions';

class NewCase extends Component {

    constructor() {
        super();
        this.state = {
            cname: '',
            name: '',
            description: '',
            patient_age: '',
            patient_sex: 'M'
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }


    componentDidMount() {
        refresh();
        this.setState({
            cname: this.props.location.cname,
            description: '',
        })
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();

        const caso = {
            cname: this.state.cname,
            name: this.state.name,
        }

        newCase(caso).then(res => {
            if (!res.error) {
                this.props.history.push({
                    pathname: "/collection/" + this.state.cname + "/caselist",
                    state: { cname: this.state.cname, success: true, cid: res.cid }
                });
            }
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-5 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <h1 className="h3 mb-3 font-weight-normal">Nuovo Caso</h1>
                            <div className="form-group">
                                <label htmlFor="name">Nome Caso:</label>
                                <input type="text"
                                    name="name"
                                    className="form-control"
                                    required="required"
                                    placeholder="ID Caso"
                                    value={this.state.name}
                                    onChange={this.onChange} />
                            </div>

                            <button type="submit" className="btn btn-primary mx-auto mr-5">Save</button>
                            <Link to={{ pathname: "/collection/" + this.state.cname + "/caselist", state: { cname: this.state.cname } }}>
                                <button className="btn btn-light ml-2">Back</button>
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(NewCase);