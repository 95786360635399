import React, {Component} from 'react';
import {getGrantedUsers, grantUser, getNoAtuhUsers} from '../services/UserFunctions';
import UserGrant from './UserGrant';
import {toast} from 'react-toastify';
import UserToGrant from './UserToGrant';
import {refresh} from '../services/UserFunctions';

export default class UserCollection extends Component {

    constructor() {
        super();
        this.state = {
            users_granted: [],
            users_to_grant: [],
            uid: '',
            user_role: 'EDITOR',
            cname: '',
        }
        this.onChange = this.onChange.bind(this);
    }


    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }


    componentDidMount() {
        refresh();
        getGrantedUsers(this.props.location.cname).then(res => {
            if (!res.error) {
                this.setState({
                    users_granted: res,
                    cname: this.props.location.cname
                })
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });

        getNoAtuhUsers(this.props.location.cname).then(res => {
            if (!res.error) {
                this.setState({
                    users_to_grant: res,
                })
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }


    grantUser = (user_to_grant) => grantUser(user_to_grant).then(() => {
        this.componentDidMount();
    })

    revokeUser = () => {
        this.componentDidMount();
    }

    render() {
        return (

            <div className="container">
                {this.state.users_to_grant.length !== 0 ? (
                    <div className="jumbotron mt-5">
                        <div className="col-sm mt-3">
                            <h1>Users to grant</h1>
                        </div>
                        <table className="table table-responsive">
                            <tbody>
                            <tr>
                                <td>Name</td>
                                <td>Last Name</td>
                                <td>Email</td>
                                <td>Role</td>
                            </tr>
                            {this.state.users_to_grant.map(user_to_grant => <UserToGrant key={user_to_grant.uid}
                                                                                         user={user_to_grant}
                                                                                         cname={this.props.location.cname}
                                                                                         userToGrant={this.grantUser}/>)}
                            </tbody>
                        </table>
                    </div>) : null}
                {this.state.users_granted.length !== 0 ? (
                    <div className="container">
                        <div className="jumbotron mt-5">
                            <div className="col-sm mt-3">
                                <h1>Users Granted</h1>
                            </div>
                            <table className="table col mx-auto mt-5">
                                <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td>Last Name</td>
                                    <td>Email</td>
                                    <td>Role</td>
                                </tr>
                                {this.state.users_granted.map(user => <UserGrant key={user.uid} user={user}
                                                                                 cname={this.props.location.cname}
                                                                                 userToRevoke={this.revokeUser}/>)}
                                </tbody>
                            </table>
                        </div>
                    </div>) : null}
            </div>
        );
    }
}