import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './openseadragon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faCommentDots, faDrawPolygon } from '@fortawesome/free-solid-svg-icons'

export default class Toolbar extends Component {
    
    render() {
        return (
            <React.Fragment>
                <div className="row" id="toolbar">
                    <div className="col-sm-10">
                        <Link style={{ float: "left", marginLeft: "2%", marginTop: "1%" }} id="back_button"
                            to={{ pathname: "/collection/"+ this.props.cname + "/case/"+ this.props.cid +"/slidelist", state: { cname: this.props.cname, cid: this.props.cid, page: this.props.page } }}>
                            <svg style={{ marginLeft: "2%", marginBottom: "1%" }} width="1.5em" height="1.5em"
                                viewBox="0 0 16 16" className="bi bi-arrow-return-left" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        </Link>
                        {this.props.polygon ? 
                            <span style={{ float: "left", marginLeft: "2%", marginTop: "1%" }} id="rect"
                                onClick={() => this.props.onChange(false)}> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-aspect-ratio" viewBox="0 0 16 16">
                                    <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
                                    <path d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z"/>
                                </svg>
                            </span>
                        : 
                        <span style={{ float: "left", marginLeft: "2%", marginTop: "1%" }} id="polygon"
                            onClick={() => this.props.onChange(true)}>
                            <FontAwesomeIcon icon={faDrawPolygon} title="Draw Polygon" size="sm"/>  
                        </span> 
                        }
                        {this.props.available_tags.length !== 0 ?
                        <span style={{ float: "left", marginLeft: "2%", marginTop: "1%" }} id="tagfilter"
                            onClick={() => this.props.setAnnotationFilterModalShow(true)}>
                            <FontAwesomeIcon icon={faFilter} title="Filter Annotations" size="xs" />  
                        </span>
                         : null}
                         <span>
                            <span style={{ float: "left", marginLeft: "2%", marginTop: "1%" }} id="tag_comments"
                                onClick={() => this.props.openCommentSection()}>
                                <FontAwesomeIcon icon={faCommentDots} title="Slide Comments" size="sm" />  
                            </span>
                         </span>
                        <span style={{ float: "left", marginLeft: "2%", marginTop: "1%" }} id="taglist"
                            onClick={() => this.props.setTagModalShow(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>
                        </span>
                        <p style={{ float:"left", marginLeft: "2%", marginTop: "1%" }}>(Case {this.props.cid}, Slide {this.props.sid})</p>
                    </div>
                    <div className="col-sm-2" id="toolDiv"></div>
                </div>
            </React.Fragment>
        )
    }
}