import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {API_URL} from '../constants/index';
import logError from '../utils/log';

axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.user_token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getReadySlide = (cname, cid, page) => {
    return trackPromise(axios
        .get(API_URL + 'collection/' + cname + '/case/' + cid + '/slide/?page='+page)
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
    );
}

export const getTodoSlide = (cname, cid) => {
    return axios
        .get(API_URL + 'slides/todo/?cname='+cname+"&cid="+cid)
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const deleteSlide = (slide) => {
    return axios
        .delete(API_URL + 'collection/' + slide.cname + '/case/' + slide.cid + '/slide/' + slide.sid + '/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const updateSlide = (slide) => {
    return axios
        .put(API_URL + 'collection/' + slide.cname + '/case/' + slide.cid + '/slide/' + slide.sid + '/', {
            description: slide.description
        })
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const newSlide = (slide, formData, description) => {
    return trackPromise(axios
        .post(API_URL + 'collection/' + slide.cname + '/case/' + slide.cid + '/slide/?description='+ description, formData)
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
    );
}

export const getThumbnail = (slide) => {
    return axios
    .get(API_URL + 'collection/'+slide.cname+'/case/'+slide.cid+'/slide/'+slide.sid+'/thumbnail/')
    .then(res => {
        if (!res.error) {
            return res.data;
        }
    }).catch(error => {
        if (error.response) {
            logError(JSON.stringify(error.response.data), new Error().stack, "error");
            return error.response.data;
        }
    })
}

export const setCompleted = (slide) => {
    return trackPromise(axios
        .post(API_URL + 'collection/' + slide.cname + '/case/' + slide.cid + '/slide/' + slide.sid + '/completed/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
    );
}

export const getOriginal = (slide) => {
    return trackPromise(axios.get(API_URL + 'collection/' + slide.cname + '/case/' + slide.cid + '/slide/'+slide.sid+'/original/', {
        responseType: 'blob'  /* or responseType: 'arraybuffer'  */         
    })
    .then(res => {
        return res.data;
    })
    .catch(error => {
        logError(JSON.stringify(error.response.data), new Error().stack, "error");
        return error.response.data;
    })
    );
}