import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { verifyToken } from '../services/UserFunctions';
import {OpenSeaDragonViewer} from './OpenSeadragonViewer';

class Viewer extends Component {

    componentDidMount() {
       verifyToken();
    }

    render() {
        return (
            <OpenSeaDragonViewer page={this.props.location.page} role={this.props.location.role} tilesource={this.props.location.tilesource}
                                 cname={this.props.location.cname} cid={this.props.location.cid}
                                 sid={this.props.location.sid}/>
        );
    }

}

export default withRouter(Viewer);
