import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import Login from './components/Login';
import Register from './components/Register';
import Profile from './components/Profile';
import CollectionList from './components/CollectionList';
import NewCollection from './components/NewCollection';
import UpdateCollection from './components/UpdateCollection';
import CaseList from './components/CaseList';
import Case from './components/Case';
import NewCase from './components/NewCase';
import UpdateCase from './components/UpdateCase';
import UserCollection from './components/UserCollection';
import SlideList from './components/SlideList';
import UpdateSlide from './components/UpdateSlide';
import NewSlide from './components/NewSlide';
import { Spinner } from './components/Spinner';
import Viewer from './components/Viewer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ChangePassword from './components/ChangePassword';

class App extends React.Component {

    render() {
        return (
            <Router>
                <div className="App">
                    <Navbar />
                    {   localStorage.user_token ?                          
                    <Route exact path="/" component={Landing} /> : <Route exact path="/" component={Login} /> }
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/profile" component={Profile} />
                        <Route exact path="/home" component={Landing} />
                        <Route exact path="/collection" component={CollectionList} />
                        <Route exact path="/newcollection" component={NewCollection} />
                        <Route exact path="/updatecollection" component={UpdateCollection} />
                        <Route exact path="/collection/:cname/caselist" component={CaseList} />
                        <Route exact path="/case" component={Case} />
                        <Route exact path="/newcase" component={NewCase} />
                        <Route exact path="/updatecase" component={UpdateCase} />
                        <Route exact path="/userCollection" component={UserCollection} />
                        <Route exact path="/collection/:cname/case/:cid/slidelist" component={SlideList} />
                        <Route exact path="/updateslide" component={UpdateSlide} />
                        <Route exact path="/newslide" component={NewSlide} />
                        <Route exact path="/changepassword" component={ChangePassword} />
                    <Route exact path="/collection/:cname/case/:cid/slide/:sid" component={Viewer} />
                    <Spinner id="spinner"/>
                </div>
                <ToastContainer />
            </Router>
        )
    }
}

export default App;
