import React, { Component } from 'react';
import jwt_decode from 'jwt-decode';
import Barchart from './charts/Barchart';
import { getAnnotationsPerYear, getAnnotatedSlidePerWeek, getTagDepth } from '../services/StatsFunctions';
import './Landing.css';
import { logOut, verifyToken } from '../services/UserFunctions';

class Landing extends Component {

    constructor() {
        super();
        this.state = {
            first_name: '',
            ascissa_apw: null,
            ordinata_apw: null,
            anno: '',
            ascissa_scw: null,
            ordinata_scw: null,
            ascissa_tag: null,
            ordinata_tag: null,
            total: null,
        }
    }

    componentDidMount() {
        verifyToken();

        if (localStorage.user_token) {
            const token = localStorage.user_token;
            const decoded = jwt_decode(token);
            var ascissa_apw, ordinata_apw, ascissa_scw, ordinata_scw, first_name;
            getAnnotationsPerYear().then(res => {
                        if(res.status === 401){
                            logOut(this);
                        }
                        ascissa_apw = res.x;
                        ordinata_apw = res.y;
                    getAnnotatedSlidePerWeek().then(res1 => {
                        if(res1.status === 401){
                            logOut(this);
                        }
                        ascissa_scw = res1.x;
                        ordinata_scw = res1.y;
                        first_name = decoded.identity.first_name
                    })
            })

            
        getTagDepth().then(res => {
            if(res.status === 401){
                logOut(this);
            }
            this.setState({
                ascissa_tag: res.x,
                ordinata_tag: res.y,
                total: res.total,
                ascissa_apw: ascissa_apw,
                ascissa_scw: ascissa_scw,
                ordinata_apw: ordinata_apw,
                ordinata_scw: ordinata_scw,
                first_name: first_name
            })
        })
        }

    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 mx-auto">
                        {!localStorage.user_token ?
                            <p className="text-center">This is a beta app developed in order to accomplish slides annotation</p>
                            : ''}
                        {localStorage.user_token ?
                            <p className="text-center">Data Charts</p>
                            : ''}
                    </div>
                </div>
                { localStorage.user_token && (this.state.ascissa_apw || this.state.ascissa_scw || this.state.ascissa_tag) ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-sm">
                            <Barchart id="tag_distribution" x={this.state.ascissa_tag} y={this.state.ordinata_tag} color="#6F8AB7" label={"Tag Distribution"} />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="col-sm">
                            <Barchart x={this.state.ascissa_apw} y={this.state.ordinata_apw} color="#FE00C4" label={"Annotations per week "} />
                        </div>
                        <div className="col-sm">
                            <Barchart x={this.state.ascissa_scw} y={this.state.ordinata_scw} color="#440182" label={"Completed slides per week "} />
                        </div>
                    </div>
                </React.Fragment>
                    : ''}
            </div>
        )
    }
}

export default Landing;