import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {updateSlide} from '../services/SlideFunction';
import {toast} from 'react-toastify';
import {refresh} from '../services/UserFunctions';

class UpdateSlide extends Component {

    constructor(props) {
        super(props);
        this.state = {
            description: this.props.location.description ? this.props.location.description : '',
            cname: this.props.location.cname,
            cid: this.props.location.cid,
            sid: this.props.location.sid
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        const slide = {
            cname: this.state.cname,
            description: this.state.description,
            cid: this.state.cid,
            sid: this.state.sid
        }

        updateSlide(slide).then(res => {
            if (!res.error) {
                this.props.history.push({
                    pathname: '/collection/'+ this.state.cname +'/case/'+ this.state.cid +'/slidelist',
                    state: {
                        cname: this.state.cname,
                        cid: this.state.cid,
                        sid: this.state.sid,
                        page: this.props.location.page,
                        success_old: true,
                        description: slide.description
                    }
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-5 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <h1 className="h3 mb-3 font-weight-normal">Modify Slide</h1>

                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input type="text"
                                       name="description"
                                       className="form-control"
                                       required="required"
                                       value={this.state.description}
                                       onChange={this.onChange} />
                            </div>

                            <button type="submit" className="btn btn-lg btn-primary mx-auto mr-5">Save</button>
                            <Link to={{
                                pathname: "/collection/"+ this.state.cname + "/case/"+ this.state.cid +"/slidelist",
                                state: {cname: this.state.cname, cid: this.state.cid, page: this.props.location.page}
                            }}>
                                <button className="btn btn-lg btn-light ml-2">Back</button>
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(UpdateSlide);