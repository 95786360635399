import axios from 'axios';
import { API_URL } from '../constants/index';

const apiPath = API_URL + 'log/';

axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.user_token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const logError = (message, stack, level) => {
    var timestamp = new Date().getFullYear() + "-" + (new Date().getMonth() + 1)+ "-" + new Date().getDate() + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds();
    return axios
        .post(apiPath, {
            message: message,
            stacktrace: stack,
            level: level,
            timestamp: timestamp
        })
        .then(res => {
            return res.data
        })
};

export default logError;