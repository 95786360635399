import React, { Component } from 'react';
import Modal from 'react-modal';
import './AnnotationFilter.css';
import Checkbox from '@material-ui/core/Checkbox';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width:'50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root')

export default class AnnotationFilter extends Component {

    constructor(props){
        super(props);
        this.state = {
            selected_tags: []
        }
        this.onChange = this.onChange.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
    }

    clearFilter = () => {
        this.setState({
            selected_tags: []
        })
        this.props.applyFilter(null);
    }

    onChange = (e) => {
        if(e.target.checked) {
            this.setState({
                selected_tags: [...this.state.selected_tags, e.target.value]
            })
        } else {
            var index = this.state.selected_tags.indexOf(e.target.value);
            if (index > -1) {
               this.setState({
                selected_tags:[...this.state.selected_tags.slice(0, index),
                ...this.state.selected_tags.slice(index + 1, )]
               })
            }     
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                style={customStyles}
                contentLabel="Legenda Tag">
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            <h4 id="annotators_title" style={{marginBottom: "2%"}}>Tag Filter </h4>
                        </div>
                    </div>
                    <div className="jumbotron">
                    <div className="row">
                        <div className="col-sm" id="tag_table">
                        <table className="table table-responsive">
                            <tbody>
                            <tr>
                                <td></td>
                                <td>Tag Name</td>
                                <td>Color</td>
                            </tr>
                            {this.props.available_tags.map((tag, i) =><tr className="tags" key={i}><td><Checkbox color="primary" size="small" onChange={this.onChange} value={tag.tag} checked={this.state.selected_tags.indexOf(tag.tag) > -1} /></td><td>{tag.tag}</td><td><span style={{backgroundColor: tag.color}} className="dot"></span></td></tr>)}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                    <br></br>
                    <div className="row">
                    <div className="col-sm">
                        <button className="btn btn-primary" id="apply_filter" disabled={this.state.selected_tags.length === 0} onClick={() => this.props.applyFilter(this.state.selected_tags)}>Apply Filter</button>
                    </div>
                    <div className="col-sm">
                        <button className="btn btn-light" id="close_button" onClick={this.props.onHide}>Close</button>
                        <button className="btn btn-light" id="clear_filter" onClick={this.clearFilter} disabled={this.state.selected_tags.length === 0}>Clear</button>
                    </div>
                </div>
                </div>
            </Modal>
        );
    }
}
