import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {updateCase} from '../services/CaseFunctions';
import {toast} from 'react-toastify';
import {refresh} from '../services/UserFunctions';

class UpdateCase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cname: this.props.location.cname,
            cid: this.props.location.cid,
            name: this.props.location.case_name ? this.props.location.case_name : '',
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        refresh();
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        const caso = {
            cname: this.state.cname,
            name: this.state.name,
            cid: this.state.cid
        }

        updateCase(caso).then(res => {
            if (!res.error) {
                this.props.history.push({pathname: '/collection/'+ this.state.cname + '/caselist',
                    state: {
                        cname: this.state.cname,
                        cid: this.state.cid,
                        success_old: true,
                        description: this.state.description
                    }
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-5 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <h1 className="h3 mb-3 font-weight-normal">Modify Case</h1>

                            <div className="form-group">
                                <label htmlFor="name">Nome Caso:</label>
                                <input type="text"
                                    name="name"
                                    className="form-control"
                                    required="required"
                                    placeholder="ID Caso"
                                    value={this.state.name}
                                    onChange={this.onChange} />
                            </div>

                            <button type="submit" className="btn btn-lg btn-primary mx-auto mr-5">Save</button>
                            <Link to={{pathname: "/collection/"+this.state.cname+"/caselist", state: {cname: this.state.cname}}}>
                                <button className="btn btn-lg btn-light ml-2">Back</button>
                            </Link></form>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(UpdateCase);