import React, {Component} from 'react';
import {register} from '../services/UserFunctions';
import {validEmailRegex} from '../constants/index';
import {toast} from 'react-toastify';
import {refresh} from '../services/UserFunctions';

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

class Register extends Component {
    constructor() {
        super();
        this.state = {
            first_name: '',
            last_name: '',
            user_email: '',
            password: '',
            confirm_password: '',
            is_admin: false,
            errors: {
                first_name: '',
                last_name: '',
                user_email: '',
                password: '',
                confirm_password: ''
            }
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        refresh();
    }

    onChange(e) {
        this.handleChange(e);
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        const newUser = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            user_email: this.state.user_email,
            password: this.state.password,
            confirm_password: this.state.confirm_password,
        }

        if (validateForm(this.state.errors)) {
            register(newUser).then(() => {
                this.props.history.push('/');
            });
        } else {
            toast.error('Invalid Form', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    handleChange = (event) => {
        event.preventDefault();
        const {name, value} = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'first_name':
                errors.first_name =
                    value.length < 2
                        ? 'First name must be 2 characters long!'
                        : '';
                break;
            case 'last_name':
                errors.last_name =
                    value.length < 2
                        ? 'Last name must be 2 characters long!'
                        : '';
                break;
            case 'user_email':
                errors.user_email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid!';
                break;
            case 'password':
                errors.password =
                    value.length < 8
                        ? 'Password must be 8 characters long!'
                        : '';
                break;
            case 'confirm_password':
                errors.confirm_password = value !== this.state.password
                    ? 'The two passwords must match'
                    : '';
                break;
            default:
                break;
        }

        this.setState({errors, [name]: value});
    }

    render() {
        const {errors} = this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-5 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <h1 className="h3 mb-3 font-weight-normal">New User</h1>
                            <div className="form-group">
                                <label htmlFor="first_name">First Name</label>
                                <input type="text"
                                       name="first_name"
                                       className="form-control"
                                       required="required"
                                       placeholder="First Name"
                                       value={this.state.first_name}
                                       onChange={this.onChange}
                                       noValidate/>
                                {errors.first_name.length > 0 &&
                                <span style={{color: "red"}} className='error'>{errors.first_name}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="last_name">Last Name</label>
                                <input type="text"
                                       name="last_name"
                                       className="form-control"
                                       required="required"
                                       placeholder="Last Name"
                                       value={this.state.last_name}
                                       onChange={this.onChange}
                                       noValidate/>
                                {errors.last_name.length > 0 &&
                                <span style={{color: "red"}} className='error'>{errors.last_name}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="user_email">Email Address</label>
                                <input type="email"
                                       name="user_email"
                                       className="form-control"
                                       required="required"
                                       placeholder="Email"
                                       value={this.state.user_email}
                                       onChange={this.onChange}
                                       noValidate/>
                                {errors.user_email.length > 0 &&
                                <span style={{color: "red"}} className='error'>{errors.user_email}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password"
                                       name="password"
                                       className="form-control"
                                       required="required"
                                       placeholder="Password"
                                       value={this.state.password}
                                       onChange={this.onChange}
                                       noValidate/>
                                {errors.password.length > 0 &&
                                <span style={{color: "red"}} className='error'>{errors.password}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirm_password">Confirm Password</label>
                                <input type="password"
                                       name="confirm_password"
                                       className="form-control"
                                       required="required"
                                       placeholder="Confirm password"
                                       value={this.state.confirm_password}
                                       onChange={this.onChange}
                                       noValidate/>
                                {errors.confirm_password.length > 0 &&
                                <span style={{color: "red"}} className='error'>{errors.confirm_password}</span>}
                            </div>

                            <button type="submit" className="btn btn-lg btn-primary btn-block">Register</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register;