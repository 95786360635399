import React, { Component } from 'react';
import { grantUser } from '../services/UserFunctions';
import './Collection.css';
import { toast } from 'react-toastify';

class UserToGrant extends Component {

    constructor() {
        super();
        this.state = {
            roles: [{ value: 'EDITOR', label: 'Editor' }, { value: 'GUEST', label: 'Guest' }],
            user_role: 'EDITOR'
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleClick() {
        if (window.confirm('Are you sure you wish to grant this user?'))
            this.onSend(this.props.user, this.state.role);
    }

    render() {
        return (
            <tr key={this.props.user.uid}>
                <td> {this.props.user.first_name}</td>
                <td> {this.props.user.last_name}</td>
                <td> {this.props.user.user_email}</td>
                <td><div className="form-group">
                    <select className="form-control" id="user_role" name="user_role" onChange={this.onChange}>
                        {this.state.roles.map(role => <option key={role.value}>{role.value}</option>)}
                    </select>
                </div>
                </td>
                <td>
                    <svg onClick={() => this.handleClick()} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10zM13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                </td>
            </tr>);
    }

    onSend(user) {
        var user_to_grant = {
            uid: user.uid,
            user_role: this.state.user_role
        };
        grantUser(user_to_grant.uid, user_to_grant.user_role, this.props.cname).then(() => {
                toast.success('User ' + user.first_name + ' ' + user.last_name +' granted ', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.userToGrant();
        });
    }
}

export default UserToGrant;


