import OpenSeaDragon from "openseadragon";
import React, { Component } from "react";
import jwt_decode from 'jwt-decode';
import './openseadragon.css';
import UserModal from './UserModal';
import TagLegendModal from './TagLegendModal';
import AnnotationFilter from './AnnotationFilter';
import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';
import {getAvailableTags} from '../services/AnnotationFunctions';
import Toolbar from './Toolbar';
import {
    createAnnotation,
    updateAnnotation,
    deleteAnnotation,
    getAnnotators,
    caricaUidAnnotation
} from '../services/AnnotationFunctions';
import { GUEST_ROLE, annotations } from '../constants/index';
import { toast } from 'react-toastify';
import CommentSection from './CommentSection';
import { verifyToken, logOut } from "../services/UserFunctions";

export class OpenSeaDragonViewer extends Component {

    constructor() {
        super();
        this.state = {
            modalOpen: false,
            taglistModalOpen: false,
            filterAnnotationModalOpen: false,
            settingModalOpen: false,
            viewer: null,
            anno: null,
            polygon: false,
            uid: null,
            label: 'Polygon',
            commentSectionOpen: false,
            users: null,
            available_tags: [],
            selected_tags: null,
        }
        this.onChange = this.onChange.bind(this);
        this.setModalShow = this.setModalShow.bind(this);
        this.setNameTag = this.setNameTag.bind(this);
        this.setTagModalShow = this.setTagModalShow.bind(this);
        this.setSettingModalShow = this.setSettingModalShow.bind(this);
        this.setAnnotationFilterModalShow = this.setAnnotationFilterModalShow.bind(this);
        this.changeAnnotation = this.changeAnnotation.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetUser = this.resetUser.bind(this);
        this.openCommentSection = this.openCommentSection.bind(this);
    }

    onChange(polygon) {
        this.setState({
            polygon: polygon,
        })
        if (this.state.viewer !== null)
            this.InitOpenseadragon(this.props, polygon, null,  this.state.selected_tags);
    }

    componentDidMount() {
        verifyToken();
        this.InitOpenseadragon(this.props, this.state.polygon, null, null);
        getAnnotators(this.props.cname, this.props.cid, this.props.sid).then(res => {
            this.setState({
                users: res
            })
        })
    }

    

    InitOpenseadragon = (props, polygon, uid, tags) => {
        this.state.viewer && this.state.viewer.destroy();

        var viewer = new OpenSeaDragon.Viewer({
            id: "openseadragon",
            prefixUrl: process.env.PUBLIC_URL + "/img/",
            tileSources: this.props.tilesource,
            maxZoomPixelRatio: 2,
            loadTilesWithAjax: true,
            imageLoaderLimit: 1,
            toolbar: "toolDiv",
            minZoomLevel: 0,
            ajaxHeaders: {
                'Authorization': 'Bearer ' + localStorage.user_token,
                'Content-Type': 'application/json'
            },
            showNavigator: true,
        });

        getAvailableTags(props.cname, props.cid, props.sid, 1).then(res => {
            if(!res.error){
                this.setState({
                    available_tags: res,
                })
            }
        })

        var anno = this.initAnnotorious(viewer, props, polygon, uid, tags);
        this.setState({ viewer: viewer, anno: anno });
    }

    initAnnotorious(viewer, props, polygon, uid, tags) {
        const config = { readOnly: props.role === GUEST_ROLE , widgets: [
            'COMMENT',
            { widget: 'TAG', vocabulary: annotations }
          ] };
        var cname = props.cname;
        var cid = props.cid;
        var sid = props.sid;
        var anno = Annotorious(viewer, config);
        var myObject;

        if (polygon) {
            anno.setDrawingTool("polygon");
        }

        caricaUidAnnotation(this.props.cname, this.props.cid, this.props.sid, 1, tags).then(res => {
                if(res.status === 401){
                    logOut(this);
                }
                myObject = JSON.parse(JSON.stringify(res.data));
                anno.setAnnotations(myObject);
                myObject.forEach(element => this.setNameTag(element));
            }).catch(err => {
                toast.error(err, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        anno.on('createAnnotation', a => createAnnotation(cname, cid, sid, a).then(res => {
            if(res.status === 401){
                logOut(this);
            }
            getAvailableTags(props.cname, props.cid, props.sid, 1).then(res => {
                if(!res.error){
                    this.setState({
                        available_tags: res,
                    })
                }            })
            if (!res.error) {
                a.real_id = res.data.real_id;
                a = res.data
                myObject = anno.getAnnotations();
                myObject.forEach(element => this.setNameTag(element));
                toast.success('Annotation created', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        );

        anno.on('updateAnnotation', annotation => updateAnnotation(cname, cid, sid, annotation).then(res => {
            if(res.status === 401){
                logOut(this);
            }
            getAvailableTags(props.cname, props.cid, props.sid, 1).then(res => {
                if(!res.error){
                    this.setState({
                        available_tags: res,
                    })
                }
            })
            annotation = res.data
            myObject = anno.getAnnotations();
            myObject.forEach(element => this.setNameTag(element));
            toast.success('Annotation updated', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            toast.error(err, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        ));

        anno.on('deleteAnnotation', annotation => deleteAnnotation(cname, cid, sid, annotation).then(res => { 
            if(!res.error){
                if(res.status === 401){
                    logOut(this);
                }
                getAvailableTags(props.cname, props.cid, props.sid, 1).then(res => {
                    if(!res.error){
                        this.setState({
                            available_tags: res,
                        })
                    }
                })
                annotation = res.data
                myObject = anno.getAnnotations();
                myObject.forEach(element => this.setNameTag(element));
                toast.success('Annotation deleted', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } 
        }  
        ));

        anno.on('cancelSelected', () => {
            myObject.forEach(element => this.setNameTag(element));
        })

        return anno;
    }

    componentWillUnmount() {
        this.state.viewer && this.state.viewer.destroy();
    }

    setModalShow(boolean) {
        this.setState({
            modalOpen: boolean
        })
    }

    setTagModalShow(boolean) {
        this.setState({
            taglistModalOpen: boolean
        })
    }

    setAnnotationFilterModalShow(boolean) {
            this.setState({
                filterAnnotationModalOpen: boolean
            })
    }

    setSettingModalShow(boolean) {
        this.setState({
            settingModalOpen: boolean
        })
    }

    changeAnnotation(user) {
        const token = localStorage.user_token;
        const decoded = jwt_decode(token);
        if (user.uid !== decoded.identity.uid) {
            this.InitOpenseadragon(this.props, this.state.polygon, user.uid, this.state.selected_tags);
            this.setState({
                modalOpen: false,
                uid: user.uid,
            })
        } else {
            this.InitOpenseadragon(this.props, this.state.polygon, null, this.state.selected_tags);
            this.setState({
                modalOpen: false,
                uid: null,
            })
        }
    }

    applyFilter(selected_tags){
        var selected_tags_string;
        if(selected_tags !== null){
            selected_tags_string = selected_tags.toString();
        } else {
            selected_tags_string = null;
        }
        this.setState({
            selected_tags: selected_tags_string,
            filterAnnotationModalOpen: false
        })
        this.InitOpenseadragon(this.props, this.state.polygon, this.state.uid, selected_tags_string);
    }

    setNameTag(annotation) {
        var tag = document.querySelector('[data-id="' + annotation.id + '"]');
        if(tag !== null){
            tag.setAttribute("name", annotation.body[0].value);
        }
    }

    resetUser = (props, polygon) => {
        this.setState({
            uid: null
        })
        this.InitOpenseadragon(props, polygon, null, null);
    }

    openCommentSection(){
        var osd = document.getElementById("openseadragon");
        if(!this.state.commentSectionOpen){
            osd.style.width = "75%";
            this.setState({
                commentSectionOpen: true
            })
        } else {
            osd.style.width = "100%";
            this.setState({
                commentSectionOpen: false
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Toolbar openCommentSection={this.openCommentSection} onChange={this.onChange} role={this.props.role} page={this.props.page} polygon={this.state.polygon} users={this.state.users} cname={this.props.cname} cid={this.props.cid} sid={this.props.sid} setModalShow={this.setModalShow} InitOpenseadragon={this.InitOpenseadragon} setTagModalShow={this.setTagModalShow} setAnnotationFilterModalShow={this.setAnnotationFilterModalShow} setSettingModalShow={this.setSettingModalShow} available_tags={this.state.available_tags} resetUser={this.resetUser} />
                <UserModal
                    show={this.state.modalOpen}
                    users={this.state.users}
                    changeAnnotation={this.changeAnnotation}
                    onHide={() => this.setModalShow(false)}
                    handleSubmit={this.handleSubmit} />
                <TagLegendModal
                    show={this.state.taglistModalOpen}
                    onHide={() => this.setTagModalShow(false)} />
                <AnnotationFilter
                    available_tags={this.state.available_tags}
                    selected_tags={this.state.selected_tags}
                    show={this.state.filterAnnotationModalOpen}
                    onHide={() => this.setAnnotationFilterModalShow(false)} 
                    applyFilter = {this.applyFilter} 
                    uid={this.state.uid} />
                <div className="row">
                    <div id="openseadragon"></div>
                    <CommentSection id="commentSection" commentSectionOpen={this.state.commentSectionOpen} cname={this.props.cname} cid={this.props.cid} sid={this.props.sid} />
                </div>
            </React.Fragment>
        );
    }
}