import React from 'react';
import { Bar } from 'react-chartjs-2';

export default class Barchart extends React.Component {

  constructor() {
    super();
    this.state = {
      chartData: {}
    };
  }

  componentDidMount() {
    this.setState({
      chartData: {
        labels: this.props.x,
        datasets: [
          {
            label: this.props.label,
            backgroundColor: this.props.color,
            hoverBackgroundColor: this.props.color,
            hoverBorderWidth: 2,
            hoverBorderColor: "#000",
            data: this.props.y
          }
        ]
      }
    })
  }

  render() {
    return (
      <div>
        <Bar
          data={this.state.chartData}
          width={60}
          height={500}
          options={{
            maintainAspectRatio: false
          }}
        />
      </div>
    );
  }
}