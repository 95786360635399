import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {newSlide} from '../services/SlideFunction';
import {toast} from 'react-toastify';
import {refresh} from '../services/UserFunctions';
import './NewSlide.css';
import { SlideDescr } from './SlideDescr';
class NewSlide extends Component {

    constructor() {
        super();
        this.state = {
            cname: '',
            cid: '',
            selectedFile: null,
            fileLabel: 'Choose file',
            description: ''
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    onFileChange = event => {

        this.setState({selectedFile: event.target.files[0], fileLabel: event.target.files[0].name});

    };

    componentDidMount() {
        refresh();
        this.setState({
            cname: this.props.location.cname,
            cid: this.props.location.cid,
        })
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        const formData = new FormData();

        formData.append(
            "file",
            this.state.selectedFile,
            this.state.selectedFile.name,
        );

        const slide = {
            cname: this.state.cname,
            cid: this.state.cid,
            file: this.state.selectedFile
        }

        newSlide(slide, formData, this.state.description).then(res => {
            if (!res.error) {
                this.props.history.push({
                    pathname: '/collection/'+ this.state.cname +'/case/'+ this.state.cid +'/slidelist',
                    state: {cname: this.state.cname, cid: this.state.cid, success: true, sid: res.sid}
                });
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    render() {
        return (
            <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mt-5 mx-auto">
                        <form noValidate onSubmit={this.onSubmit}>
                            <h1 className="h3 mb-3 font-weight-normal">New Slide</h1>
                            <div className="row">
                                <div className="input-group mb-3">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="inputGroupFile01"
                                               onChange={this.onFileChange}/>
                                        <label className="custom-file-label"
                                               htmlFor="inputGroupFile01">{this.state.fileLabel}</label>
                                    </div>
                                </div>
                            </div>
                            <SlideDescr onChange={this.onChange}/>
                            <div className="row" style={{float: "right"}}>
                                <button disabled={this.state.selectedFile === null || this.state.selectedFile === undefined} type="submit" aria-pressed="true" className="btn btn-primary">Upload</button>
                                <Link to={{pathname: "/collection/"+ this.props.location.cname + "/case/"+ this.props.location.cid +"/slidelist", state: {cname: this.state.cname, cid: this.state.cid}}}>
                                    <button className="btn btn-light ml-3" aria-pressed="true">Back</button>
                                </Link>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }

}

export default withRouter(NewSlide);