import React, {Component} from 'react';
import {getCollection} from '../services/CollectionFunctions';
import {Link, withRouter} from 'react-router-dom';
import './Collection.css';
import Collection from './Collection';
import {toast} from 'react-toastify';
import {verifyToken, logOut} from '../services/UserFunctions';

class CollectionList extends Component {
    constructor() {
        super();
        this.state = {
            collections: [],
            is_admin: false
        }
    }

    componentDidMount() {
        verifyToken();

        if (this.props.location.state) {

            if (this.props.location.state.success) {
                toast.success('Collection created with cname: ' + this.props.location.state.cname, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            if (this.props.location.state.success_old) {
                toast.success('Collection ' + this.props.location.state.cname + ' updated with description: ' + this.props.location.state.description, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }

        if (this.state.collections.length === 0) {
            getCollection().then(res => {
                if(res.status === 401){
                    logOut(this);
                }
                if (!res.error) {
                    this.setState({
                        collections: res,
                        is_admin: localStorage.is_admin === "true" ? true : false
                    })
                } else {
                    toast.error(res.error, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        }
    }


    removeCollection = (collection) => {
        getCollection().then(res => {
            if(res.status === 401){
                logOut(this);
            }
            this.setState({
                collections: res
            })
        });
    }

    render() {
        return (
            <div className="container">
                <div className="jumbotron mt-5">
                    <div className="row">
                        <div className="col-sm mt-3">
                            <h1>Collections</h1>
                        </div>
                        {this.state.is_admin ? (<div className="col-sm-1 mt-1">
                            <Link to="/newcollection" className="nav-link">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-file-earmark-plus"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                                    <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z"/>
                                    <path fillRule="evenodd"
                                          d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z"/>
                                </svg>
                            </Link>
                        </div>) : null}

                    </div>
                    <table className="table table-responsive col mx-auto">
                        <tbody>
                        <tr>
                            <td>Collection Name</td>
                            <td>Collection Description</td>
                            <td>Role</td>
                        </tr>
                        {this.state.collections.map(collection => <Collection key={collection.cname}
                                                                              collection={collection}
                                                                              collectionToRemove={this.removeCollection}/>)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default withRouter(CollectionList);