import axios from 'axios';
import { API_URL } from '../constants/index';
import logError from '../utils/log';

axios.interceptors.request.use(
    config => {
        config.headers.authorization = 'Bearer ' + localStorage.user_token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getAnnotationsPerYear = () => {
    return axios
        .get(API_URL + 'collection/stats/week/annotations/total/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const getAnnotatedSlidePerWeek = () => {
    return axios
        .get(API_URL + 'collection/stats/week/slides/completed/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}

export const getTagDepth = () => {
    return axios
        .get(API_URL + 'collection/stats/tiles/total/')
        .then(res => {
            if (!res.error) {
                return res.data;
            }
        }).catch(error => {
            if (error.response) {
                logError(JSON.stringify(error.response.data), new Error().stack, "error");
                return error.response.data;
            }
        })
}
