import React, { Component } from 'react';
import { revokeUser } from '../services/UserFunctions';
import './Collection.css';
import { toast } from 'react-toastify';

class UserGrant extends Component {

    handleClick() {
        if (window.confirm('Are you sure you wish to revoke this user?'))
            this.onCancel(this.props.cname, this.props.user.uid)
    }

    render() {
        return (


            <tr key={this.props.user.uid}>

                <td> {this.props.user.first_name}</td>
                <td> {this.props.user.last_name}</td>
                <td> {this.props.user.user_email}</td>
                <td> {this.props.user.user_role}</td>
                <td>
                    <svg onClick={() => this.handleClick()} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
                    </svg>
                </td>
            </tr>);
    }

    onCancel(cname, uid) {
        revokeUser(cname, uid).then(res => {
                toast.success('User ' + res.uid + ' removed ', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.userToRevoke();
        });
    }
}

export default UserGrant;


