import React, { Component } from 'react';
import { getCases } from '../services/CaseFunctions';
import { Link, withRouter } from 'react-router-dom';
import './Collection.css';
import Case from './Case';
import { GUEST_ROLE } from '../constants/index';
import { toast } from 'react-toastify';
import { verifyToken, logOut } from '../services/UserFunctions';

class CaseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cases: [],
            cname: this.props.match.params.cname,
            role: ''
        }
    }

    componentDidMount() {
        verifyToken();

        if (this.props.location.state.success) {
            toast.success('Case created with ID: ' + this.props.location.state.cid, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        if (this.props.location.state.success_old) {
            toast.success('Case ' + this.props.location.state.cid + ' updated with desc: ' + this.props.location.state.description, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        if (this.state.cases.length === 0) {
            getCases(this.props.location.state.cname).then(res => {
                if(res.status === 401){
                    logOut(this);
                }
                if (!res.error) {
                    this.setState({
                        cases: res,
                        role: this.props.location.state.role
                    })
                } else {
                    toast.error(res.error, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        }
    }


    removeCase = (cases) => {
        getCases(this.state.cname).then(res => {
            if(res.status === 401){
                logOut(this);
            }
            if (!res.error) {
                this.setState({
                    cases: res
                })
            } else {
                toast.error(res.error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    render() {
        return (
            <div className="container">
                <div className="jumbotron mt-5">
                    <div className="row">
                        <div className="col-sm mt-3">
                            <h1>Cases</h1>
                        </div>
                        <div className="col-sm-1 mt-1">
                            <Link to="/collection">
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-return-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                                </svg>
                            </Link>
                        </div>
                        {this.state.role !== GUEST_ROLE ? (
                            <div className="col-sm-1 mt-1">
                                <Link style={{ textDecoration: 'none' }}
                                    to={{ pathname: "/newcase", cname: this.state.cname }}>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16"
                                        className="bi bi-file-earmark-plus" fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                                        <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                                        <path fillRule="evenodd"
                                            d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z" />
                                    </svg>
                                </Link>
                            </div>) : null
                        }
                    </div>
                    <table className="table table-responsive" id="case_list">
                        <tbody>
                            <tr>
                                <td>ID Caso</td>
                                <td>Nome Caso</td>
                            </tr>
                            {this.state.cases.map((caso) => <Case key={caso.cid} caso={caso} cname={this.state.cname}
                                role={this.state.role} caseToRemove={this.removeCase} />)}
                        </tbody>
                    </table>
                </div>
            </div >
        )
    }
}

export default withRouter(CaseList);